import React from 'react';
import { PageProps } from 'gatsby';
import Layout from '../components/layout/Layout';
import MetaData from '../components/seo/MetaData';
import SmallContainer from '../components/layout/SmallContainer';
import Container from '../components/layout/Container';
import Fruitbowl from "../components/images/icons/fruitbowl.png";

const Tak: React.FunctionComponent<PageProps> = () => (
  <Layout>
    <MetaData title="Tak for beskeden" />
    <div className="bg-white">
        <SmallContainer className="pt-4 text-center">
          <h1>Tak for beskeden!</h1>
          <p>
              Vi kigger på den og vender tilbage hurtigst muligt.
              I mellemtiden kan du kigge rundt blandt alle vores opskrifter.
          </p>
        </SmallContainer>
        <SmallContainer className="pt-4">
            <div className="text-center">
                <img 
                    src={Fruitbowl}
                    className="mx-auto"
                    style={{ borderRadius: 10 }}
                    alt="Tak for beskeden"
                />
            </div>
        </SmallContainer>
      </div>
  </Layout>
);

export default Tak;
